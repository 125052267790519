.Search__Container{
    width: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: .5rem;
}

.Search__btn{
    background: rebeccapurple;
    color: #fff;
}

.Search__output{
    background: rebeccapurple;
    padding: .4rem;
    z-index: 99;
    color: #fff;
    border-radius: 10px;
}

