.Navbar{
    background: #fff;
   margin: 2rem;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 1rem;
   font-weight: 600;
}

.nav__logo img{
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    cursor: pointer;
}

.nav__social__links{
 
    display: flex;
    gap: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
    
}

.nav__links{
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 1rem;
}

.nav__links ul{
    list-style: none;
    cursor: pointer;
    display: flex;
    gap: 2rem;
    font-size: 1.2rem;
    color: #000;
    transition: 1s;
    position: relative;
    
}

.nav__links ul li{
    transition: 1s ease-in-out;
}


.nav__links ul li:hover{
    width: 100%;
    height: 3px;
   background: purple;

}



/* .nav__links ul li{
    width: 100%;
    height: 3px;
    background: black;
    border: 1px black;
} */

.nav__social__links a {
    color: #000;
} 

.nav__social__links a:hover{
    animation: tofro 1s infinite alternate;
}

.Menu{
    display: none;
}
.nav__link{
    display: none;
}
.close__Menu{
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: .5rem;
    cursor: pointer;
}
@keyframes tofro {
    0%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(-10px);
    }
}

@media(max-width:600px){
    .nav__social__links{
 
        display: flex;
        gap: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        
    }
}


@media(max-width:850px){
    .Menu{
        display: block;
    }

    .nav__links{
        display: none;
    }
    .nav__link{
        position: fixed;
        flex-direction: column;
        background: purple;
        right: 30px;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        z-index: 99;
        display: block;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 10px;

    }
    .nav__link ul li{
        color: #fff;
    }
    .nav__link ul{
        display: flex;
        list-style: none;
        gap: 1rem;
    }

    .nav__social__link {
        color: #fff;
        display: flex;
        gap: 1rem;
        flex-direction: row;
    }

    .nav__social__link a{
        color: #fff;
    }
}