.data{
    margin: 2rem;
}

.data__main {
    width: 700px;
    position: relative;
}
.data__main h1{
    color:#fff;
    font-size: 60px;
 
  
}
.blog__data span{
  background-color: red;
  padding: 0.6rem;
  color: #fff;
  border-radius: 10px;
}
.blog__data span:hover{
  background-color: rgb(180, 10, 10);
}
.data__main img{
    width: 200px;
    position: absolute;
  right: 5rem;
  top: 3rem;
    z-index: -1;
 
    
}

.blog__data{
    margin-top: 5rem;
    background: lightcyan;
    padding: 1rem;
    border-radius: 10px;
    transition:  1s;
    overflow: hidden;
    

}

.blog__data p{
  padding: .2rem;
}


.blog__data img{
 width: 100%;
 height: 200px;
border-radius: 0px;


}
.blog__data h4{
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
}

.blog__post button{
   padding: .7rem;
   border-radius: 10px;
   background: lightcyan;
   color: #000;
   border: none;
   outline: none;
   margin-top: 2rem;
   float: right;
}



.loader {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: absolute;
    background: red;
    box-sizing: border-box;
    animation: flipX 1s linear infinite;
    align-items: center;
    justify-content: center;
  

    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
 
  
  }
  
  @keyframes flipX {
    0% {
      transform: perspective(200px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(200px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
      transform: perspective(200px) rotateX(-180deg) rotateY(-180deg);
    }
  }



  @media(max-width:700px){
    .data__main h1{
      font-size: 50px;
    }
    .data__main img{
      display: none;
    }
    .data__main{
      width: fit-content;
    }
    .blog__data:hover {
      transform: scale(1);
    }
  }