.contact__container{
    color: #fff;
    padding: 1rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
   width: 100%;
    color: #000;
    padding: 4rem;
    gap: 2rem;
   
}

.font{
    font-size: 40px;
    
}

.contact__form{
    width: 60%;
    background-color: rgb(138, 81, 196);
    padding: 2rem;
    border-radius: 10px;
    font-weight: 700;
}

form{
    display: flex;
    flex-direction: column;
   
}

form label{
    color: #fff;
}

form input{
    outline: none;
    padding: .5rem;
    border-radius: 10px;
    border: none;
}
form textarea{
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 10px;
}

form button{
    margin-top: 1rem;
    border: none;
    outline: none;
    padding: .6rem;
    background: #fff;
    color: #000;
    border-radius: 10px;
    font-weight: 600;
}



.about__me{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rebeccapurple;
    padding: 1rem;
    color: #fff;
    border-radius: 10px;
    gap: 1.4rem;
}
.about__img img{
    width: 100%;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    background: cyan;
    padding: 5px;
}
.about__info{
    text-align: center;
}



@media(max-width:900px){
    .contact__container{
        flex-direction: column;
       
    }
    .contact__form{
        width: 100%;
        background-color: rgb(138, 81, 196);
        padding: 2rem;
        border-radius: 10px;
    }
    
}


@media(max-width:600px){
    .contact__container{
     
        padding: 1rem;
    }
    .contact__form{
        padding: 1rem;
    }
}
