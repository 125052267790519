.blog__header img{
    width: 100%;
    object-fit: contain;
    
    height: 300px;
}
.blog__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: lightcyan;
    padding: 2rem;
    border-radius: 10px;
}

.blog__body{
    background:lightcyan;
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1.3rem;
}

.blog__body img{
    width: 100%;
    height: 400px;
    object-fit: contain;
    
}


@media(max-width:700px){
    .blog__body img{
        width: 100%;
        height: 500px;
        object-fit: contain;
        border-radius: 10px;
    }
}